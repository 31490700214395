.mt-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: opacity;
}

// Slide animation

.mt-slide {
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 300ms;
}

.mt-slideUp {
  animation-name: slideAnimationUp;
}

.mt-slideBottom {
  animation-name: slideAnimationUp;
  transform-origin: top;
}

.mt-slideLeft {
  animation-name: slideAnimationLeft;
}

.mt-slideRight {
  animation-name: slideAnimationRight;
}

.mt-hideSlide {
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 200ms;
}

.mt-hideSlideUp {
  animation-name: hideAnimationSlideUp;
}

.mt-hideSlideBottom {
  animation-name: hideAnimationSlideUp;
}

.mt-hideSlideLeft {
  animation-name: hideAnimationSlideLeft;
}

.mt-hideSlideRight {
  animation-name: hideAnimationSlideRight;
}

@keyframes slideAnimationUp {
  0% {
    opacity: 0;
    bottom: -100%;
  }

  100% {
    opacity: 1;
    bottom: 0%;
  }
}

@keyframes slideAnimationLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slideAnimationRight {
  0% {
    left: -100%;
  }

  100% {
    left: 0%;
  }
}

@keyframes hideAnimationSlideUp {
  0% {
    opacity: 1;
    bottom: 0%;
  }

  100% {
    opacity: 0;
    bottom: -100%;
  }
}

@keyframes hideAnimationSlideLeft {
  0% {
    right: 0%;
  }

  100% {
    right: -100%;
  }
}

@keyframes hideAnimationSlideRight {
  0% {
    left: 0%;
  }

  100% {
    left: -100%;
  }
}
