.mt-tab-root {
  font-family: inherit;
  padding: 6px 12px;
  overflow: hidden;
  position: relative;
  font-size: 0.875rem;
  max-width: 264px;
  min-width: 72px;
  box-sizing: border-box;
  min-height: 48px;
  text-align: center;
  flex-shrink: 0;
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
  text-transform: uppercase;
}

@media (min-width: 927px) {
  .mt-tab-root {
    min-width: 160px;
  }
}

.mt-tab-label-icon {
  min-height: 72px;
  padding-top: 9px;
}

.mt-tab-label-icon .mt-tab-wrapper > *:first-child {
  margin-bottom: 6px;
}

.mt-tab-inherit {
  color: inherit;
  opacity: 0.7;
}

.mt-tab-inherit.mt-tab-selected {
  opacity: 1;
}

.mt-tab-inherit.mt-tab-disabled {
  opacity: 0.5;
}

.mt-tab-full-width {
  flex: 1 1 0;
  max-width: none;
}

.mt-tab-wrapped {
  font-size: 0.75rem;
  line-height: 1.5;
}

.mt-tab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
}
