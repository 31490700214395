.mt-drawer-paper {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}

.mt-drawer-paperAnchorRight {
  left: auto;
  right: 0;
}

.mt-drawer-animate {
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 300ms;
  animation-name: slideAnimationLeft;
}

.mt-drawer-animate-hide {
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-duration: 200ms;
  animation-name: hideAnimationSlideLeft;
}

@keyframes slideAnimationLeft {
  0% {
    right: -100%;
  }

  100% {
    right: 0%;
  }
}

@keyframes hideAnimationSlideLeft {
  0% {
    right: 0%;
  }

  100% {
    right: -100%;
  }
}
