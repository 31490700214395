table {
  table-layout: auto;
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
}

thead {
  display: table-header-group;
}

tbody {
  display: table-row-group;
}

tr {
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
}

th,
td {
  display: table-cell;
  padding: 4px;
  text-align: left;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
  color: get(contrast-text-secondary-87);
}
