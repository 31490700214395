.mt-switch-root {
  width: 58px;
  height: 38px;
  display: inline-flex;
  padding: 12px;
  z-index: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;
  vertical-align: middle;
}

@media print {
  .mt-switch-root {
    -webkit-print-color-adjust: exact;
  }
}

.mt-switch-switchBase {
  top: 0;
  left: 0;
  color: #fafafa;
  z-index: 1;
  position: absolute;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 9px;
}

.mt-switch-switchBase.mt-checked {
  transform: translateX(20px);
}

.mt-switch-switchBase.mt-checked + .mt-switch-track {
  opacity: 0.5;
}

.mt-switch-colorPrimary.mt-checked {
  color: #0084ce;
}

.mt-switch-colorPrimary.mt-checked + .mt-switch-track {
  background-color: #0084ce;
}

.mt-switch-colorPrimary.mt-checked:hover {
  background-color: rgba(0, 132, 206, 0.04);
}

@media (hover: none) {
  .mt-switch-colorPrimary.mt-checked:hover {
    background-color: transparent;
  }
}

.mt-switch-colorSecondary.mt-checked {
  color: #00ad45;
}

.mt-switch-colorSecondary.mt-checked + .mt-switch-track {
  background-color: #00ad45;
}

.mt-switch-colorSecondary.mt-checked:hover {
  background-color: rgba(0, 173, 69, 0.04);
}

@media (hover: none) {
  .mt-switch-colorSecondary.mt-checked:hover {
    background-color: transparent;
  }
}

.mt-switch-input {
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
}

.mt-switch-thumb {
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  background-color: currentcolor;
}

.mt-switch-track {
  width: 100%;
  height: 100%;
  opacity: 0.38;
  z-index: -1;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 7px;
  background-color: #000;
}
