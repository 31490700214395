.mt-list-root {
  list-style: none;
  margin: 0;
  padding: 0 8px;
  position: relative;
}

.mt-list-item-root {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding: 16px 8px;
  justify-content: flex-start;
  text-decoration: none;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.mt-list-item-root:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.mt-list-item-root.selected,
.mt-list-item-root.selected:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.mt-list-item-root.disabled {
  opacity: 0.5;
}

@media (hover: none) {
  .mt-list-item-root:hover {
    background-color: transparent;
  }
}

.mt-list-item-text-root {
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.mt-list-item-icon-root {
  color: rgba(0, 0, 0, 0.54);
  display: inline-flex;
  min-width: 56px;
  flex-shrink: 0;
}
