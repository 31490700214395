.mt-circle-loader {
  position: relative;
  width: 50px;
  height: 50px;
  border-style: solid;
  border-width: 3.6px;
  border-color: get(secondary-main);
  border-left-color: transparent;
  transform: translateZ(0);
  animation: mt-spin 0.6s infinite linear;

  &,
  &::after {
    border-radius: 50%;
  }
}

@keyframes mt-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
