.mt-bottom-navigation-root {
  height: 56px;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.mt-bottom-navigation-action-root {
  flex: 1;
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  max-width: 168px;
  min-width: 80px;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.mt-bottom-navigation-action-root:first-child {
  padding-left: 8px;
}

.mt-bottom-navigation-action-root:last-child {
  padding-right: 8px;
}

.mt-bottom-navigation-action-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.mt-bottom-navigation-action-label {
  opacity: 1;
  font-size: 0.75rem;
  transition: font-size 0.2s, opacity 0.2s;
  white-space: nowrap;
  transition-delay: 0.1s;
}
