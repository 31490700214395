@import 'src/styles/config';
@import './config';
@import './classes';

html,
body {
  background-color: $body-color;
  color: get(contrast-text-secondary-87);
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  min-width: 300px;
}

.disable-scroll {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

a,
.link {
  color: $base100;
  text-decoration: underline;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

#root {
  height: 100%;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html.scroll-lock {
  overflow: hidden;

  // only for Safari iOS
  @supports (-webkit-touch-callout: none) {
    height: 100vh;
    overflow: hidden;

    body {
      position: fixed;
      width: 100%;
      top: 0;
      overflow: hidden;
    }
  }
}

// Hide icon in input[type="password"] for Microsoft Edge

::-ms-reveal {
  display: none;
}

// initial css properties
// example:
// --mt-color-primary: #00bcd4;
@include init-css-properties;

.main-container {
  padding: 0 24px;
  width: 100%;
  position: relative;

  &.limited {
    max-width: $maxContentWidth;
    margin: auto;
  }

  @media (max-width: $sm) {
    padding: 0 12px;
  }
}
