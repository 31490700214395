@import 'src/styles/config';
// The reason to keep styles globally for the global error page
// is due to NextJS doesn't download the styles imported in global-error.tsx
// to the client which makes usage of SCSS modules impossible

.global-error-page {
  &__header {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    min-height: $headerHeight;
  }

  &__header-logo {
    cursor: pointer;
    display: block;
    height: 80px;
    width: auto;
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  &__content-wrapper {
    margin: 16px 0;
  }

  &__content {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background: $primary-black-16;

    @media screen and (min-width: $sm) {
      min-height: 70vh;
    }
  }

  &__content-title {
    font-weight: $font-weight-700;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  &__content-btn {
    margin: 0.5rem;
    height: $sizing2d5Rem;
    border-radius: $border-radius-default;
  }

  &__content-btn--reload {
    @include button-ghost-colors;
  }
}
