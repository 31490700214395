// Fade animation

.mt-fade {
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-duration: 300ms;
  animation-name: fadeAnimation;
}

.mt-hideFade {
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-duration: 200ms;
  animation-name: hideAnimationFade;
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hideAnimationFade {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
