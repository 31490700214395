.mt-svg-icon-root {
  fill: currentcolor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.mt-svg-icon-color-primary {
  color: #0084ce;
}

.mt-svg-icon-color-secondary {
  color: #00ad45;
}

.mt-svg-icon-color-action {
  color: rgba(0, 0, 0, 0.54);
}

.mt-svg-icon-color-error {
  color: #f44336;
}

.mt-svg-icon-color-disabled {
  color: rgba(0, 0, 0, 0.26);
}

.mt-svg-icon-font-size-inherit {
  font-size: inherit;
}

.mt-svg-icon-font-size-small {
  font-size: 1.25rem;
}

.mt-svg-icon-font-size-large {
  font-size: 2.1875rem;
}
