@import 'src/styles/config';
.root {
  padding-top: 20px;
  padding-left: 16px;
  // mobile
  @media screen and (max-width: $xs) {
    padding-left: 12px;
  }
}

.sidebar {
  width: 248px;
}

.logo {
  display: block;
  height: 40px;
  margin: 0 auto;
}

.loadingText {
  margin-top: 40vh;
  display: block;
  width: 100%;
  text-align: center;
}
