.mt-progress {
  height: 40px;
  transform: rotate(-90deg);
  width: 40px;
  stroke: get(secondary-main);
  border-radius: 50%;

  &-circle {
    fill: none;
    stroke: inherit;
    stroke-dasharray: 100 100;
    stroke-width: 4;
    transition: stroke-dashoffset 1s ease-in-out;
  }
}
