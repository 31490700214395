@mixin top3 {
  top: 24px;
}

@mixin bottom3 {
  bottom: 24px;
}

@mixin right3 {
  right: 24px;
}

@mixin left3 {
  left: 24px;
}

@mixin center {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

@mixin centerBoth {
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
}

.mt-snackbar {
  z-index: 1400;
  position: fixed;
  display: flex;
  left: 8px;
  right: 8px;
  justify-content: center;
  align-items: center;
}

/* Styles applied to the root element if `anchorOrigin={{ 'top', 'center' }}`. */

.mt-snackbar-anchorOriginTopCenter {
  @include top3;
  @include center;
}

/* Styles applied to the root element if `anchorOrigin={{ 'bottom', 'center' }}`. */

.mt-snackbar-anchorOriginBottomCenter {
  @include bottom3;
  @include center;
}

/* Styles applied to the root element if `anchorOrigin={{ 'top', 'right' }}`. */

.mt-snackbar-anchorOriginTopRight {
  left: auto;

  @include top3;
  @include right3;
}

/* Styles applied to the root element if `anchorOrigin={{ 'bottom', 'right' }}`. */

.mt-snackbar-anchorOriginBottomRight {
  left: auto;

  @include bottom3;
  @include right3;
}

/* Styles applied to the root element if `anchorOrigin={{ 'top', 'left' }}`. */

.mt-snackbar-anchorOriginTopLeft {
  right: auto;

  @include top3;
  @include left3;
}

/* Styles applied to the root element if `anchorOrigin={{ 'bottom', 'left' }}`. */

.mt-snackbar-anchorOriginBottomLeft {
  right: auto;

  @include bottom3;
  @include left3;
}
