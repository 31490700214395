button {
  font-family: inherit;
}

.mt-button-base-root {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  font-family: inherit;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.mt-button-base-root::-moz-focus-inner {
  border-style: none;
}

.mt-button-base-root.mt-button-disabled {
  cursor: default;
  pointer-events: none;
}

.mt-button-contained {
  padding: 6px 15px;
  min-width: 64px;
  min-height: 36px;
  box-sizing: border-box;
  margin-top: 6px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: $border-radius-default;
  margin-bottom: 6px;

  @include button-primary-colors;
}

.mt-button-contained:hover.mt-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

.mt-button-contained-size-small {
  padding: 4px 10px;
  font-size: 0.8125rem;
}

.mt-button-contained-size-large {
  padding: 8px 22px;
  font-size: 0.9375rem;
}

// @media (hover: none) {
//  .mt-button-contained:hover {
//    text-decoration: none;
//    box-shadow: none;
//    background-color: $red-hover;
//  }
// }

.mt-button-outlined {
  min-width: 64px;
  box-sizing: border-box;
  margin-top: 6px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  margin-bottom: 6px;
  padding: 5px 15px;
  border: 1px solid #fbb400;
  color: #fbb400;
  background-color: transparent;
}

.mt-button-outlined:hover {
  background-color: rgba(187, 134, 252, 0.04);
}

.mt-button-outlined:focus {
  background-color: rgba(187, 134, 252, 0.12);
}

.mt-button-outlined.mt-button-disabled {
  color: rgba($primary-white, 0.38);
}

.mt-button-outlined-size-small {
  padding: 3px 9px;
  font-size: 0.8125rem;
}

.mt-button-outlined-size-large {
  padding: 7px 21px;
  font-size: 0.9375rem;
}
