.mt-typography-h1 {
  margin: 0;
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.15;
}

.mt-typography-h2 {
  margin: 0;
  font-size: 3.75rem;
  font-weight: 300;
  line-height: 1.2;
}

.mt-typography-h3 {
  margin: 0;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.16;
}

.mt-typography-h4 {
  margin: 0;
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 1.06;
}

.mt-typography-h5 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
}

.mt-typography-h6 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2rem;
}

.mt-typography-subtitle1 {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.mt-typography-subtitle2 {
  margin: 0;
  font-size: 0.8125rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.mt-typography-body1 {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.mt-typography-body2 {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
}

.mt-typography-caption {
  margin: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.33;
}

.mt-typography-overline {
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.6;
  text-transform: uppercase;
}

// ==================== typography additionals styles

.mt-typography-alignCenter {
  text-align: center;
}

.mt-typography-error {
  color: get(error-main);
}
