@import 'src/styles/config';
#chatbot-chat {
  animation: chatbot-mobile-closed 0.1s ease-in-out forwards;
}

html.chatbot-mobile-opened #chatbot-chat {
  animation: chatbot-mobile-opened 0.1s ease-in-out forwards;
}
// Fix for CLS
@keyframes chatbot-mobile-opened {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes chatbot-mobile-closed {
  0% {
    display: none;
  }

  100% {
    display: block;
  }
}
