.mt-simple-backdrop-root {
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
}

.mt-modal-root {
  position: fixed;
  inset: 0;
  z-index: 1300;

  & > * {
    opacity: 0;
    transition: opacity 0.3s;

    &:first-child {
      opacity: 1;
    }

    &:last-child {
      opacity: 1;
    }
  }

  .mobileBottomViewModal,
  .mobileFullViewModal,
  .scrollModal {
    flex-direction: column;
    overflow-y: auto;
    justify-content: unset;
    align-items: unset;
    padding: 64px 24px;
  }

  .overlayJackpot {
    backdrop-filter: blur(4px);
  }

  .mobileBottomViewModal,
  .mobileFullViewModal {
    @media screen and (max-width: $sm) {
      padding: 0;
      background: $base10;
    }
  }

  .mobileBottomViewModal {
    @media screen and (max-width: $sm) {
      max-height: 100%;
      height: auto;
      bottom: 0;
      border-radius: 0;
    }
  }
}

.mt-modal {
  background: #1c1c1c;
  max-width: 480px;
  width: 100%;
  padding: 32px 16px 24px;
  font-size: 14px;
  color: $base100;
  line-height: 1.4;
  position: relative;
  margin: auto;
  border-radius: $border-radius-8;

  &.hiddenModal {
    opacity: 0;
  }

  &.mt-modal-mobile-without-radius {
    @media screen and (max-width: $sm) {
      border-radius: 0;
      padding: env(safe-area-inset-top) 16px 24px;
      height: 100%;
      box-shadow: unset;
    }
  }

  &.sm-modal {
    max-width: 360px;
  }

  &.full-modal {
    max-width: 100%;
    height: 100%;

    .mt-modal-header {
      justify-content: space-between;

      img {
        margin-left: 0;
      }
    }
  }

  .modal-close {
    position: absolute;
    color: $base100;

    .mt-svg-icon-root {
      width: 24px;
      height: 24px;
    }
  }

  &.promo {
    padding-top: 0;
    text-align: center;

    .modal-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      margin: 16px 0 8px;
      text-align: center;

      &::first-letter {
        text-transform: uppercase;
      }

      &.secondary {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }

  &.promo-fullscreen {
    width: auto;
    height: auto;
    max-width: unset;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-radius: 8px 8px 0 0;

    .modal-close {
      top: 48px;
      z-index: 1;
    }

    .textPart {
      // margin-left: $promoFullscreenModalOffset;
      // width: calc(100% - $promoFullscreenModalOffset);
      width: 100%;
      background: #1c1c1c;
      border-radius: 0 0 8px 8px;
      padding: 16px 16px 24px;
    }

    .fullscreenImg {
      display: block;
      width: 317px;
      position: relative;
    }
  }

  .button-holder {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    .button {
      margin: 0;
      width: 100%;
    }
  }

  .text {
    line-height: 1.4;
    margin: 8px 0;
  }

  .modal-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: $base100;
  }

  .button {
    width: 100%;
    min-height: 40px;
    margin: 24px 0 16px;
    text-transform: capitalize;
    font-weight: 500;
  }

  .divider {
    background-color: $borderColor;
    height: 2px;
    width: 100%;
    margin: 16px 0;
  }

  .mt-modal-header {
    display: flex;
    align-items: center;

    img {
      margin-left: 4px;
    }
  }

  .img-holder {
    margin: -120px auto 0;
    width: 400px;
    height: 240px;

    @media (max-width: $sm) {
      width: 286px;
      height: 172px;
      margin-top: -86px;
    }

    &.landscape-none {
      @media screen and (max-width: $sm) and (orientation: landscape) and (max-height: 640px) {
        display: none;
      }
    }

    span {
      display: block !important;
    }

    img {
      display: block;
      margin: auto;
      width: 100%;
      height: 100%;
    }
  }

  .text-red {
    color: $brand-accent-primary;
  }

  .text-gc {
    color: #fbb400;
  }

  .text-sc {
    color: #95e759;
  }

  .text-green {
    color: $brand-accent-secondary;
  }

  .text-gold {
    color: $warning;
  }

  .text-bigger {
    font-size: 16px;
    line-height: 1.2;
  }

  .text-extra-bigger {
    font-size: 24px;
    line-height: 1.2;
  }

  .text-smaller {
    font-size: 12px;
    line-height: 1.4;
  }

  .text-medium {
    font-weight: 500;
  }

  .text-semi-bold {
    font-weight: 600;
  }

  .text-bold {
    font-weight: 700;
  }

  .text-caption {
    font-size: 10px;
    line-height: 1.4;
  }

  .text-white {
    color: #fff;
  }

  .text-primary {
    color: $textColorPrimary;
  }

  .text-brand-secondary {
    color: $brand-accent-secondary;
  }

  .text-inherit {
    color: inherit;
  }

  a.text-white {
    text-decoration: underline;
    font-weight: 500;
  }

  .mt24 {
    margin-top: 24px !important;
  }

  .mt16 {
    margin-top: 16px !important;
  }

  .mt12 {
    margin-top: 12px !important;
  }

  .mt8 {
    margin-top: 8px !important;
  }

  .mb24 {
    margin-bottom: 24px !important;
  }

  .mb16 {
    margin-bottom: 16px !important;
  }

  .mb12 {
    margin-bottom: 12px !important;
  }

  .mb8 {
    margin-bottom: 8px !important;
  }

  .mb4 {
    margin-bottom: 4px !important;
  }

  .mb0 {
    margin-bottom: 0 !important;
  }

  .mt0 {
    margin-top: 0 !important;
  }

  .text-center {
    text-align: center;
  }

  .text-uppercase {
    text-transform: uppercase;
  }
}
