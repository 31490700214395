.mt-appbar {
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  color: get(contrast-text-secondary);
  background-color: get(header-background);

  &-fixed {
    top: 0;
    left: auto;
    right: 0;
    position: fixed;
  }
}

.has-sticky {
  .mt-appbar-fixed {
    top: $stickyBarHeight;
  }
}
