.mt-linear-progress-root {
  height: 4px;
  overflow: hidden;
  position: relative;
  background-color: rgb(158, 208, 236);
}

@media print {
  .mt-linear-progress-root {
    -webkit-print-color-adjust: exact;
  }
}

.mt-linear-progress-bar {
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
  transition: transform 0.2s linear;
  transform-origin: left;
  background-color: #0084ce;
}

.mt-linear-progress-bar-determinate {
  transition: transform 0.4s linear;
}
