.mt-hidden-mobile {
  @media screen and (max-width: $sm) {
    display: none !important;
  }
}

.mt-hidden-desktop {
  @media screen and (min-width: $sm) {
    display: none;
  }
}

.mt-helper-text {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.33;
}
