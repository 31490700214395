.mt-paper {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: $border-radius-default;
  // rgba 87
  color: get(contrast-text-secondary);
  background-color: get(dialog-background);

  &-elevation-off {
    box-shadow: none;
  }
}
