.mt-icon-button-root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

.mt-icon-button-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mt-icon-button-root-disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.26);
}

.mt-icon-button-root-colorInherit {
  color: inherit;
}

.mt-icon-button-root-colorPrimary {
  color: #3f51b5;
}

.mt-icon-button-root-colorPrimary:hover {
  background-color: rgba(63, 81, 181, 0.04);
}

.mt-icon-button-root-colorSecondary {
  color: rgb(236, 64, 122);
}

.mt-icon-button-root-colorSecondary:hover {
  background-color: rgba(236, 64, 122, 0.04);
}

.mt-icon-button-root-sizeSmall {
  padding: 3px;
  font-size: small;
}

.mt-icon-button-root-edgeStart {
  margin-left: -12px;

  &.sizesmall {
    margin-left: -3px;
  }
}

.mt-icon-button-root-edgeEnd {
  margin-right: -12px;

  &.sizesmall {
    margin-right: -3px;
  }
}

.mt-icon-button-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
}
