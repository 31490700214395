input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  caret-color: get(contrast-text-secondary-87);
  -webkit-text-fill-color: get(contrast-text-secondary-87);
  -webkit-box-shadow: 0 0 0 60px #333 inset;
  background-color: #333;
  background-clip: content-box;
}

.mt-input-root {
  max-width: 100%;
  width: 100%;
}

.mt-input-base {
  position: relative;
  border-radius: $border-radius-default;
  border-color: transparent;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    border-radius: $border-radius-default;
    background-color: transparent;
    z-index: 1;
    pointer-events: none;
    border-style: solid;
    border-width: 2px;
    border-color: inherit;
  }

  input {
    position: relative;
    height: 48px;
    width: 100%;
    padding: 14px 16px;
    outline: 0;
    font-size: $fontMD;
    font-weight: 400;
    line-height: 1;
    background-color: #333;
    border-radius: $border-radius-8;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.55) inset;
    border: none;

    &:where(.mt-input-small) {
      height: 40px;
      padding: 10.5px 14px;
    }

    color: $base100;

    &::placeholder {
      color: $base100;
      font-family: inherit;
      font-size: $fontMD;
    }

    &:disabled {
      color: var(--mt-contrast-text-secondary-38);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-clip: content-box;
      caret-color: get(contrast-text-secondary-87);
      -webkit-text-fill-color: get(contrast-text-secondary-87);
      -webkit-box-shadow: 0 0 0 60px #333 inset;
      background-color: #333;
    }
  }
}

.mt-input-underlined {
  input {
    padding: 7px 0;
  }

  &::after {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  &:hover:not(.mt-input-focus, .mt-input-error)::after {
    border-top: none;
    border-left: none;
    border-right: none;

    @media (any-pointer: coarse) {
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}

.mt-input-focus {
  &::after {
    height: calc(100% - 4px);
    width: calc(100% - 4px);
  }

  &.mt-input-underlined::after {
    border-top: none;
    border-left: none;
    border-right: none;
  }
}

.mt-input-error {
  & input,
  & input::placeholder {
    color: $brand-accent-primary;
  }

  & input:focus {
    color: $base100;
  }

  &.mt-input-underlined::after,
  &.mt-input-focus .mt-input-underlined::after {
    border-top: none;
    border-left: none;
    border-right: none;
  }
}

.mt-input-helper-text {
  display: block;
  width: auto;
  font-weight: 400;
  margin: 3px 14px 0;
  font-size: 0.75rem;
  color: $base100;
  line-height: 1.33;
  text-align: left;
}

.mt-input-adornment-root {
  position: relative;
  width: 100%;

  .mt-input-adornment {
    z-index: 1;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $sizing3Rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1;
  }

  .mt-input-start-adornment {
    left: 0;
  }

  .mt-input-and-adornment {
    right: 0;
  }

  &:where(.mt-input-small) .mt-input-start-adornment {
    width: 40px;
    height: 40px;
  }

  &:where(.mt-input-small) .mt-input-and-adornment {
    width: 40px;
    height: 40px;
  }

  input {
    padding-right: 47px;

    &:where(.mt-input-small) {
      padding-right: 40px;
    }
  }

  .mt-input-start-adornment + .mt-input-root {
    input {
      padding-right: 14px;
      padding-left: 47px;

      &:where(.mt-input-small) {
        padding-left: 40px;
      }
    }
  }
}

select:disabled,
input:disabled {
  opacity: 0.7 !important;
}
