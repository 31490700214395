.mt-checkbox-root {
  display: inline-flex;
  flex: 0 0 auto;
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  border-radius: 50%;
  color: get(primary-main);
  cursor: pointer;
  position: relative;
  align-items: center;
  user-select: none;
  justify-content: center;
  background-color: transparent;
  background-position: center;
  transition: 550ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background: rgba(0, 132, 206, 0.04) radial-gradient(circle, transparent 1%, rgba(0, 132, 206, 0.04) 1%)
      center/15000%;
  }

  &:active {
    background-color: rgba(0, 132, 206, 0.04);
    background-size: 100%;
    transition: 0ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  input {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: inherit;
  }

  svg {
    display: inline-block;
    width: 0.68em;
    height: 0.68em;
    fill: $base100;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
}
