@mixin init-css-properties() {
  @each $theme, $colors in $themes {
    html {
      @each $key, $value in $colors {
        --mt-#{$key}: #{$value};
      }
    }
  }
}

@function get($key) {
  @return var(--mt-#{$key});
}

@function calculate-components-width($menuWidth, $cols, $gridSize) {
  @return calc(calc((100vw - $menuWidth - 24px - var(--scroll, 0px)) / $cols) * $gridSize);
}

@function calculate-components-width-limited($baseWidth, $cols, $gridSize) {
  @return calc(calc(($baseWidth - 48px - var(--scroll, 0px)) / $cols) * $gridSize);
}

/**
 * Converts pixel values to rem units for custom values.
 *
 * @function px-to-rem
 * @param {...number|string} $values - Pixel values to convert. Accepts numbers (in px) or strings.
 * @return {list} - A list of rem values or unchanged strings.
 *
 * @important This function should ONLY be used for custom pixel values
 *            that are not defined in `variables.scss`.
 *            For predefined values, refer to the variables directly.
 */
@function px-to-rem($values...) {
  $remValues: (); // Initialize an empty list for the rem values

  // Loop through each value passed into the function
  @each $value in $values {
    @if type-of($value) == 'number' {
      // Ensure we properly calculate the rem value, including unit conversion
      $remValue: $value / 16; // Divide by 16 to convert px to rem
      $remValues: append($remValues, #{$remValue}rem); // Append the converted value with 'rem' unit
    }

    @if type-of($value) == 'string' {
      // If the value is a string, just append it to the list
      $remValues: append($remValues, $value);
    }
  }

  @return $remValues;
}
