.mt-tooltip-tip {
  z-index: 1500;
  pointer-events: none;
  position: fixed;
  margin: 5px;
  color: #fff;
  padding: 4px 8px;
  font-size: 0.625rem;
  max-width: 300px;
  width: 100%;
  word-wrap: break-word;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
  opacity: 0;
}

.mt-tooltip__show {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
