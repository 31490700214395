.mt-tabs-root {
  position: relative;
  display: flex;
  min-height: 48px;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &:hover {
    .mt-tabs-arrow {
      display: flex;
    }
  }
}

.mt-tabs-scrollable {
  overflow-y: scroll;
}

.mt-tabs-flex-container {
  display: flex;

  @media screen and (min-width: $xl) {
    margin: auto;
  }
}

.mt-tabs-indicator {
  width: 100%;
  bottom: 0;
  height: 2px;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #00ad45;
}

.mt-tabs-arrow {
  $bg-color: rgba(1, 55, 90, 0.5);

  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  background-color: $bg-color;
  color: #fff;

  &:hover {
    background-color: $bg-color;
  }

  &-left {
    left: auto;
  }

  &-right {
    right: 0;
  }
}
