.mt-snackbar-content {
  min-width: 288px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  font-size: 0.875rem;
  line-height: 1.43;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
